import VueApexCharts from "vue-apexcharts"

const displays_chart = {
    components: {
        Apexchart: VueApexCharts
    },
    created () {
        this.CHART_COLORS = ["#C50802", "#156073", "#DDC37C", "#9F5A8A", "#2c343b", "#D14C2D"]
        this.CHART_MARKERS = {
            size: 4,
            colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
            strokeColors: this.CHART_COLORS,
            strokeWidth: 2
        }
        this.CHART_DROP_SHADOW = {
            enabled: true,
            enabledOnSeries: [0, 1, 2, 3, 4, 5],
            top: 7,
            left: 0,
            blur: 7,
            color: this.CHART_COLORS,
            opacity: 0.2
        }
        this.CHART = {
            fontFamily: "Quicksand, sans-serif",
            toolbar: {
                show: false
            }
        }
        this.CHART_BASE_OPTIONS = {
            chart: {
                animations: {
                    speed: 600,
                    dynamicAnimation: {
                        speed: 150
                    }
                },
                fontFamily: "Quicksand, sans-serif",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            legend: {
                show: false
            },
            colors: this.CHART_COLORS,
            stroke: {
                curve: "smooth",
                width: 2,
                colors: this.CHART_COLORS
            },
            dataLabels: {
                enabled: false
            },
            yaxis: {
                forceNiceScale: true
            },
            tooltip: {
                onDatasetHover: {
                    highlightDataSeries: true
                }
            }
        }
    }
}

export default displays_chart
