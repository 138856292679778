import { mapGetters } from "vuex"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

const handles_documents = {
    mixins: [misc_utilities],
    methods: {
        owner_attachments_except_types (owner_type, owner_id, types) {
            return this.attachments.filter(attachment => {
                return (attachment.owner_type === owner_type) && (attachment.owner_id === owner_id) && (types.indexOf(attachment.type) < 0)
            })
        },
        owner_attachments_of_types (owner_type, owner_id, types) {
            return this.attachments.filter(attachment => {
                return (attachment.owner_type === owner_type) && (attachment.owner_id === owner_id) && (types.indexOf(attachment.type) >= 0)
            })
        },
        document_type_to_glyph (type) {
            if(type === this.constants("attachment_types", "TYPE_PDF").id) return "file-pdf"
            if(type === this.constants("attachment_types", "TYPE_DOC").id) return "file-word"
            if(type === this.constants("attachment_types", "TYPE_VIDEO").id) return "file-video"
            return "file"
        },
        document_type_to_constant (mime_type) {
            if(mime_type.match(/pdf/i)) return this.constants("attachment_types", "TYPE_PDF").id
            if(mime_type.match(/word/i)) return this.constants("attachment_types", "TYPE_DOC").id
            if(mime_type.match(/video/i)) return this.constants("attachment_types", "TYPE_VIDEO").id
            return this.constants("attachment_types", "TYPE_OTHER").id
        },
        owner_documents (owner_type, owner_id) {
            const type_exceptions = [this.constants("attachment_types", "TYPE_IMAGE").id, this.constants("attachment_types", "TYPE_BANNER").id]
            return this.owner_attachments_except_types(owner_type, owner_id, type_exceptions)
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        attachments () {
            return this.entity_records("attachment")
        }
    }
}

export default handles_documents
