import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import Timeline from "@/custom/components/Timeline"

const events_data_source = {
    components: {
        Timeline
    },
    methods: {
        listing_actions (listing_id) {
            if(!listing_id) return []
            return this.entity_records("listing_action").filter(listing_action => listing_action.listing_id === listing_id)
        },
        listing_showings (listing_id) {
            if(!listing_id) return []
            return this.entity_records("listing_showing").filter(listing_showing => listing_showing.listing_id === listing_id)
        },
        listing_activities (listing_id) {
            const activities = [
                ...this.listing_actions(listing_id).map(record => {
                    return {
                        type: "listing_action",
                        id: record.id,
                        action_id: record.action_id,
                        description: record.description,
                        event_date: record.executed_on,
                        event_end_date: null,
                        feedback: null
                    }
                }),
                ...this.listing_showings(listing_id).map(record => {
                    const feedback = this.entity_records("listing_feedback").find(listing_feedback => listing_feedback.listing_showing_id === record.id)
                    const description = [record.from_name]
                    if(!!feedback && !!feedback.comments) description.push(this.nibnut_filter("nibnut.truncate", [feedback.comments, 90]))
                    return {
                        type: "listing_showing",
                        id: record.id,
                        action_id: 0,
                        description: description.join(": "),
                        event_date: record.starts_at,
                        event_end_date: record.ends_at,
                        feedback,
                        cancelled: !!record.deleted_at
                    }
                })
            ]
            return orderBy(activities, "event_date", "desc")
        }
    },
    computed: {
        ...mapGetters(["entity_records"])
    }
}

export default events_data_source
