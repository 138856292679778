import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

const displays_listing = {
    mixins: [profile_utilities, addl_misc_utilities],
    methods: {
        tag_is_active (tag_ids, tag) {
            if(!tag_ids) return false
            return tag_ids.indexOf(tag.id) >= 0
        },
        toggle_tag (tag_ids, tag) {
            const index = tag_ids.indexOf(tag.id)
            if(index >= 0) tag_ids.splice(index, 1)
            else tag_ids.push(tag.id)
        },
        active_tag (tags, tag_ids) {
            return tags.filter(tag => {
                return this.tag_is_active(tag_ids, tag)
            })
        },
        website_is_active (record, website) {
            return this.tag_is_active(record.website_ids, website)
        },
        toggle_website (record, website) {
            return this.toggle_tag(record.website_ids, website)
        },
        active_websites (record) {
            return this.websites.filter(website => {
                return this.website_is_active(record, website)
            })
        },
        standardized_field_value (record, field, default_value = "") {
            return record[field] || (record.mls ? record.mls[field] : default_value)
        },
        listing_property_type (record) {
            const property_type = this.property_types.find(property_type => property_type.id === record.property_type_id) || {}
            return property_type.name
        },
        listing_is_land_only (record) {
            return !!record && !record.size
        }
    }
}

export default displays_listing
