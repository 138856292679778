const can_select_listings = {
    methods: {
        start_selecting (mode = "show") {
            this.selection_mode = mode
        },
        stop_selecting () {
            this.selection_mode = ""
        },
        toggle_selection (listing_id, selection = null) {
            selection = selection || this.selection
            const index = selection.indexOf(listing_id)
            if(index >= 0) selection.splice(index, 1)
            else selection.push(listing_id)
        }
    },
    data () {
        return {
            selection_mode: "",
            selection: []
        }
    }
}

export default can_select_listings
