/*
    <send-listings-dialog
        :show.sync="composing"
        :listing-ids="send_selection"
        :contacts="listing.contacts || []"
    />
*/
import can_select_listings from "./CanSelectListings"

const can_send_listing_selection = {
    mixins: [can_select_listings],
    components: {
        SendListingsDialog: () => import("@/custom/dialogs/SendListingsDialog")
    },
    watch: {
        composing: "maybe_stop_composing"
    },
    methods: {
        start_composing () {
            this.composing = true
        },
        maybe_stop_composing () {
            if(!this.composing) this.stop_selecting()
        }
    },
    data () {
        return {
            send_selection: [],
            composing: false
        }
    }
}

export default can_send_listing_selection
