import string_utilities from "@/nibnut/mixins/StringUtilities"

const addl_string_utilities = {
    mixins: [string_utilities],
    methods: {
        slugify (value) {
            if(!value) return value
            let slug = value.toLowerCase().replace(/-+/g, "_")
            slug = slug.replace(/@/g, "-at-")
            slug = slug.replace(/[^\-a-zA-Z0-9]+/g, "")
            slug = slug.replace(/[-\s]+/g, "-")
            return slug
        },
        compact_selection_label (list, selection, multiple_main_items = "{n} items", multiple_minor_items = "{n} sub-items") {
            if(!list || !list.length || !selection || !selection.length) return ""
            if(selection.length === 1) {
                const item = list.find(item => item.id === selection[0])
                return item ? item.name : ""
            }

            if(selection.length) {
                const levels = [
                    [],
                    []
                ]
                selection.forEach(id => {
                    const item = list.reduce((found, item) => {
                        if(!found) {
                            if(item.id === id) return item
                            if(item.sub_levels) return item.sub_levels.find(sub_item => sub_item.id === id) || found
                        }
                        return found
                    }, null)
                    if(item) {
                        const all_selected = (item.sub_levels && !item.sub_levels.find(sub_item => selection.indexOf(sub_item.id) < 0))
                        if((item.id === id) || all_selected) levels[0].push(item.name)
                        else if(item.sub_levels && !all_selected) {
                            item.sub_levels.forEach(sub_item => {
                                if(sub_item.id === id) levels[1].push(sub_item.name)
                            })
                        }
                    }
                })

                let label = (levels[0].length === 1) ? levels[0][0] : this.translate(multiple_main_items, { n: levels[0].length })
                if(levels[1].length) {
                    if(levels[1].length === 1) label += ` - ${levels[1][0]}`
                    else label += ` - ${this.translate(multiple_minor_items, { n: levels[1].length })}`
                }

                return label
            }

            return ""
        }
    }
}

export default addl_string_utilities
