import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"

const mls_field_name = (field) => {
    if(field === "state") return "StateOrProvince"
    if(field === "zip") return "PostalCode"
    if(field === "price") return "ListPrice"
    if(field === "sale_price") return "ClosePrice"
    if(field === "size") return "BuildingAreaTotal"
    if(field === "size_units") return "BuildingAreaUnits"
    if(field === "land_size") return "LotSizeArea"
    if(field === "land_size_units") return "LotSizeUnits"
    if(field === "land_dimensions") return "LotSizeDimensions"
    if(field === "parking_spots") return "ParkingTotal"
    if(field === "construction_year") return "YearBuilt"
    if(field === "on_market_on") return "OnMarketDate"
    if(field === "price_frequency") return "LeaseAmountFrequency"

    return field.replace(/(?:^|_)(.)/g, (token0, token1) => token1.toUpperCase())
}

const addl_misc_utilities = {
    mixins: [misc_utilities, string_utilities],
    methods: {
        load_vocabularies (vocabularies) {
            if(this.profile_id) {
                return this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "term",
                        query: {
                            vocabularies
                        }
                    }
                )
            }
            return Promise.resolve()
        },
        load_default_vocabularies (additional_vocabularies = []) {
            return this.load_vocabularies([
                this.constants("vocabularies", "VOCABULARY_PROPERTY_TYPES").id,
                this.constants("vocabularies", "VOCABULARY_BUILDING_FEATURES").id,
                this.constants("vocabularies", "VOCABULARY_LOT_FEATURES").id,
                this.constants("vocabularies", "VOCABULARY_VIEW_TYPES").id,
                this.constants("vocabularies", "VOCABULARY_WATERFRONT_FEATURES").id,
                this.constants("vocabularies", "VOCABULARY_NEIGHBORHOODS").id,
                ...(additional_vocabularies || [])
            ])
        },
        vocabulary (identifier) {
            if(!!this.app_context && !!this.app_context.constants && !!this.app_context.constants.vocabularies) {
                const vocabulary_id = this.app_context.constants.vocabularies[identifier].id
                return orderBy(this.entity_records("term").filter(term => {
                    return !term.parent_id && (term.vocabulary === vocabulary_id)
                }).map(term => {
                    if(term.sub_levels === undefined) {
                        term.sub_levels = []
                        if(term.children_ids && term.children_ids.length) {
                            term.sub_levels = this.entity_records("term", term.children_ids)
                            if(identifier === "VOCABULARY_PROPERTY_TYPES") {
                                term.sub_levels = orderBy(term.sub_levels, term => {
                                    if(term && term.slug) {
                                        if(identifier === "VOCABULARY_PROPERTY_TYPES") {
                                            if(term.slug.match(/^single-family/i)) return `000001${term.name}`
                                            if(term.slug.match(/^condo/i)) return `000002${term.name}`
                                            if(term.slug.match(/^land/i)) return `000003${term.name}`
                                        }
                                        if(term.slug.match(/^other/i)) return `zzzzzzzzzzzzzzzzzzzzzzz${term.name}`
                                    }
                                    return term.name
                                })
                            }
                        }
                    }
                    return term
                }), term => {
                    if(term && term.slug) {
                        if(identifier === "VOCABULARY_PROPERTY_TYPES") {
                            if(term.slug.match(/^single-family/i)) return `000001${term.name}`
                            if(term.slug.match(/^condo/i)) return `000002${term.name}`
                            if(term.slug.match(/^land/i)) return `000003${term.name}`
                        }
                        if(term.slug.match(/^other/i)) return `zzzzzzzzzzzzzzzzzzzzzzz${term.name}`
                    }
                    return term.name
                }, "asc")
            }
            return []
        },
        default_term_ids (identifier) {
            const term_ids = []
            this.vocabulary(identifier).filter(term => {
                if(term.is_default) term_ids.push(term.id)
                if(term.sub_levels) {
                    term.sub_levels.forEach(sub_term => {
                        if(sub_term.is_default) term_ids.push(sub_term.id)
                    })
                }
            })
            return term_ids
        },
        term_path_value (term_id) {
            const term = this.entity_record("term", term_id)
            let value = ""
            if(term) {
                value = term.name
                if(term.parent_id) {
                    value = this.string_concat(" ", this.term_path_value(term.parent_id), value)
                }
            }
            return value
        },
        term_is_active (term, list) {
            return !!term && !!list && (list.indexOf(term.id) >= 0)
        },
        terms_all_active (identifier, list) {
            const terms = this.vocabulary(identifier)
            return terms ? terms.filter(term => {
                return this.term_is_active(term, list)
            }) : []
        },
        are_equal (value1, value2) {
            if(value1 === value2) return true
            if(value1 && value2) return (`${value1}`).toLowerCase() === (`${value2}`).toLowerCase()
            return false
        },
        listing_mls_value (mls, field) {
            if(!mls) return undefined
            return mls[mls_field_name(field)]
        },
        date_is_this_year (date) {
            if(date) return this.$dayjs(date).isSame(this.$dayjs(), "year")
            return false
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        size_units () {
            return Object.values(this.constants("units")).filter(unit => unit.building)
        },
        land_size_units () {
            return Object.values(this.constants("units")).filter(unit => unit.land)
        },
        all_property_types () {
            return this.vocabulary("VOCABULARY_PROPERTY_TYPES")
        },
        property_types () {
            const types = this.all_property_types.filter(term => {
                return !!term.slug && !term.slug.match(/commerc/i)
            })
            return (types.length === 1) ? types[0].sub_levels : types
        },
        neighborhoods () {
            return this.vocabulary("VOCABULARY_NEIGHBORHOODS")
        },
        amenities () {
            const amenities = []
            const append_amenity = (identifier) => {
                const sub_levels = this.vocabulary(identifier)
                if(sub_levels && sub_levels.length) {
                    const vocabulary = this.app_context.constants.vocabularies[identifier]
                    amenities.push({
                        id: vocabulary.id,
                        parent_id: 0,
                        name: vocabulary.name,
                        sub_levels
                    })
                }
            }
            append_amenity("VOCABULARY_BUILDING_FEATURES")
            append_amenity("VOCABULARY_LOT_FEATURES")
            append_amenity("VOCABULARY_VIEW_TYPES")
            append_amenity("VOCABULARY_WATERFRONT_FEATURES")

            return amenities
        },
        lifestyles () {
            return this.vocabulary("VOCABULARY_LIFESTYLES")
        },
        websites () {
            return this.entity_records("website")
        },
        my_websites () {
            if(!!this.profile && !!this.profile.id) {
                return this.websites.filter(website => {
                    return website.user_ids.indexOf(this.profile.id) >= 0
                })
            }
            return []
        }
    }
}

export default addl_misc_utilities
