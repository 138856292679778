import { Portal } from "portal-vue"

/*
        <Portal to="destination-modal-destination" :disabled="!zoomed">
        ....
        </Portal>
        <destination-modal
            v-if="!!zoomed"
            :show.sync="zoomed"
            :title="zoom_title"
        />
*/

const uses_destination_modal = {
    mounted () {
        setTimeout(() => {
            window.dispatchEvent(new Event("resize"))
        }, 1000)
    },
    components: {
        Portal,
        DestinationModal: () => import("@/custom/dialogs/DestinationModal")
    },
    methods: {
        toggle_zoom (title, zoomed = true) {
            if(!!this.zoomed && (this.zoom_title === title)) {
                this.zoomed = false
                this.zoom_title = ""
            } else {
                this.zoom_title = title
                this.zoomed = zoomed
            }
        }
    },
    data () {
        return {
            zoom_title: "",
            zoomed: false
        }
    }
}

export default uses_destination_modal
