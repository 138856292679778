import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

import EventsCalendar from "@/custom/components/EventsCalendar"

const is_calendar_data_source = {
    mixins: [is_remote_data_table_source],
    components: {
        EventsCalendar,
        ShowingEditorDialog: () => import("@/custom/dialogs/ShowingEditorDialog")
    },
    watch: {
        editing_event_record: "stop_editing"
    },
    methods: {
        view_changed (range) {
            this.set_state_values(range)
            this.refresh()
        },
        date_is_past (date) {
            return date.isSameOrBefore(this.$dayjs(), "minute")
        },
        start_editing (event, event_record) {
            this.event_record = event_record
            this.event = event
            this.editing_event_record = true
        },
        stop_editing () {
            if(!this.editing_event_record) {
                this.editing_event_record = false
                if(this.delete_fc) this.delete_fc()
                this.delete_fc = null
                this.event = null
                this.event_record = null
            }
        },
        event_record_feedback (event_record) {
            if(!event_record || (event_record._entity !== "listing_showing")) return {}
            return this.entity_records("listing_feedback").find(feedback => {
                return feedback.listing_showing_id === event_record.id
            }) || {}
        }
    },
    computed: {
        state_identifier () {
            return "events-calendar"
        },
        can_refresh () {
            return !!this.profile_id && !!this.state.from && !!this.state.to
        },
        fields () {
            return ["fieldset::calendar", "ns::listing;fieldset::calendar"]
        },
        showing_events () {
            return this.rows.map(showing => {
                const listing = this.entity_record("listing", showing.listing_id)
                let cssClass = "confirmed"
                const date = this.$dayjs(showing.starts_at)
                if(this.date_is_past(date)) cssClass = "past"
                else if(!showing.confirmed_at) cssClass = "not-confirmed"
                let content = `<a href="/listing/${listing.uuid}" class="btn btn-link btn-light btn-sm"><i class="las la-external-link-square-alt"></i></a>`
                const feedback = this.event_record_feedback(showing)
                if(feedback && (!feedback.ratings || !feedback.message)) {
                    content = `<span class="btn btn-link btn-light btn-sm disabled rated"><i class="las la-star"></i></span>${content}`
                }
                return {
                    start: showing.starts_at,
                    end: showing.ends_at,
                    title: listing ? listing.computed_display_title : "",
                    content,
                    class: cssClass,
                    deletable: (cssClass !== "past"),
                    draggable: (cssClass !== "past"),
                    resizable: (cssClass !== "past"),
                    _entity: "listing_showing",
                    _id: showing.id
                }
            })
        },
        events () {
            return this.showing_events
        }
    },
    props: {
        activeView: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(years?|month|week|day)$/i),
            default: "week"
        },
        timeCellHeight: {
            type: Number,
            default: 80
        }
    },
    data () {
        return {
            entity: "listing_showing",
            relation_ids: ["listing"],
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                from: null,
                to: null
            },
            event: null,
            editing_event_record: false,
            event_record: null,
            delete_fc: null
        }
    }
}

export default is_calendar_data_source
