<template>
    <div>
        <div v-if="waiting" class="page-waiting">
            <loader size="lg" />
        </div>
        <div
            v-show="!waiting"
            id="content"
            :class="{ narrow }"
            class="page"
        >
            <div v-if="status===404">
                <slot name="not-found">
                    <div class="empty">
                        <h5 class="empty-title">{{ translate("Please stay on the path!") }}</h5>
                        <p class="empty-subtitle">{{ translate("Page not found") }}</p>
                    </div>
                </slot>
            </div>
            <div v-else-if="status===401">
                <slot name="unauthorized">
                    <div class="empty">
                        <h5 class="empty-title">{{ translate("That's kind of private...") }}</h5>
                        <p class="empty-subtitle">{{ translate("Unauthorized") }}</p>
                    </div>
                </slot>
            </div>
            <div id="onboarding" v-else-if="status===418">
                <slot name="onboarding">
                    <div class="empty">
                        <h5 class="empty-title">{{ translate("Welcome!") }}</h5>
                        <p class="empty-subtitle">{{ translate("Please select the area(s) you focus on:") }}</p>
                        <div class="empty-action">
                            <form-input
                                id="mls_id"
                                name="mls_id"
                                v-model="mls_id"
                                :required="true"
                                :disabled="onboarding"
                            >
                                <template v-slot:label>{{ translate("Please provide your MLS #") }}</template>
                                <template v-slot:hint>{{ translate("So we can link your listings to your account.") }}</template>
                            </form-input>
                            <form-multi-select
                                id="neighborhoods"
                                name="neighborhoods"
                                v-model="neighborhood_ids"
                                :options="neighborhoods"
                                :max-selection="setting('max_focus_areas')"
                                display="chips"
                                :compact-selection="compact_selection_label(neighborhoods, neighborhood_ids, '{n} areas', '{n} minor areas')"
                                :required="true"
                                :can-toggle-all="false"
                                :disabled="onboarding"
                            >
                                <template v-slot:label>{{ translate("Please select the area(s) you focus on:") }}</template>
                            </form-multi-select>
                            <default-button
                                color="primary"
                                :waiting="onboarding"
                                :disabled="onboarding || !neighborhood_ids.length"
                                @click.prevent="onboard"
                            >
                                {{ translate("Done!") }}
                            </default-button>
                        </div>
                    </div>
                </slot>
            </div>
            <div v-if="status===200" :id="id" class="page-content">
                <div class="page-content-header columns">
                    <div
                        :class="{ 'col-6': has_slot('page-actions'), 'col-12': !has_slot('page-actions') }"
                        class="column col-lg-12 d-flex"
                    >
                        <slot name="page-title">
                            <h3>{{ title }}</h3>
                            <!--
                            <page-back
                                :is-root="isRoot"
                                :fallback="backNavigationFallback"
                                :title="title"
                            />
                            //-->
                        </slot>
                    </div>
                    <div v-if="has_slot('page-actions')" class="column col-6 col-lg-12 page-actions">
                        <slot name="page-actions"></slot>
                    </div>
                    <slot name="page-title-addendum"></slot>
                </div>
                <slot></slot>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import ui_utilities from "@/nibnut/mixins/UiUtilities"
import handles_errors from "@/nibnut/mixins/HandlesErrors"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"
import addl_string_utilities from "@/custom/mixins/AddlStringUtilities"
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormMultiSelect from "@/custom/components/FormMultiSelect"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import Loader from "@/custom/components/Loader"

export default {
    name: "Page",
    mixins: [addl_profile_utilities, ui_utilities, handles_errors, is_nibnut_component, addl_misc_utilities, addl_string_utilities],
    components: {
        FormInput,
        FormMultiSelect,
        DefaultButton,
        Loader
    },
    mounted () {
        this.load()
    },
    watch: {
        $route: "load",
        profile_id: "delayed_page_guard",
        global_search_result_selection: "handle_global_search_result_click"
    },
    methods: {
        load () {
            if(window.location.hash && !!this.hashScrollTimeout) {
                setTimeout(() => {
                    this.scroll_to_hash(window.location.hash)
                }, this.hashScrollTimeout)
            }
            this.page_guard()
        },
        page_guard () {
            if(!this.profile_id && window.location.search && window.location.search.match(/(\?|&)password-reset=/)) {
                this.$emit("statused", 307) // temporary redirect
                this.login({ panel_id: "reset" })
            } else if(!this.profile_id && window.location.search && window.location.search.match(/(\?|&)invitation=/)) {
                this.$emit("statused", 307) // temporary redirect
                this.login({ panel_id: "signup" })
            } else if(this.$route.meta) {
                if(this.$route.meta.admin_route) {
                    if(!this.profile_id || !this.is_at_least_administrator) {
                        if(!this.profile_id) this.login()
                        this.$emit("statused", this.profile_id ? 401 : 307)
                    } else this.additional_page_guards()
                } else if(this.$route.meta.realtor_route) {
                    if(!this.profile_id || !this.is_at_least_realtor) {
                        if(!this.profile_id) this.login()
                        this.$emit("statused", this.profile_id ? 401 : 307)
                    } else this.additional_page_guards()
                } else if(!!this.$route.meta.login_required && !this.profile_id) {
                    this.login({ panel_id: true })
                    this.$emit("statused", 307)
                } else this.additional_page_guards()
            } else this.additional_page_guards()
        },
        maybe_cancel_login () {
            if(!!this.login_request && !!this.login_request.panel_id) this.$store.dispatch("UNREQUEST_LOGIN")
        },
        additional_page_guards () {
            this.maybe_cancel_login()
            if(!!this.is_at_least_realtor && (!this.profile.mls_id || !this.profile.neighborhood_ids || !this.profile.neighborhood_ids.length)) {
                this.load_default_vocabularies()
                this.mls_id = this.profile.mls_id
                this.neighborhood_ids = this.profile.neighborhood_ids
                this.$emit("statused", 418) // needs on-boarding
            } else {
                if(window.gtag) {
                    window.gtag("set", "page_path", document.location.pathname)
                    window.gtag("event", "page_view")
                }
                this.$emit("statused", 200)
            }
        },
        delayed_page_guard () {
            setTimeout(() => {
                this.page_guard()
            }, 250)
        },
        login (data = {}) {
            this.$store.dispatch("REQUEST_LOGIN", data)
        },
        scroll_to_hash (hash) {
            // Make sure to set the target's class to nibnut-scroll-target so the scroll is padded for the site's header's height
            this.scroll_to(document.getElementById(hash.replace(/#/, "")))
        },
        onboard () {
            this.onboarding = true
            this.$store.dispatch("RECORD_SAVE", {
                entity: "user",
                id: this.profile.id,
                data: { mls_id: this.mls_id, neighborhood_ids: this.neighborhood_ids }
            }).then(() => {
                this.additional_page_guards()
            }).catch(this.receive_error).then(() => {
                this.onboarding = false
            })
        },
        handle_global_search_result_click (search_result) {
            if(search_result) {
                if(this.globalSearchHandler) this.globalSearchHandler(search_result)
                else if(search_result._entity) this.$router.push({ name: `${search_result._entity}.edit`, params: { id: search_result.slug || search_result.id } })
            }
        }
    },
    computed: {
        ...mapState(["login_request", "global_search_result_selection"])
    },
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ""
        },
        waiting: {
            type: Boolean,
            default: false
        },
        narrow: {
            type: Boolean,
            default: false
        },
        status: {
            type: Number,
            default: 200
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        backNavigationFallback: {
            type: Object, // { title, href: (string | object) }
            default: null
        },
        hashScrollTimeout: {
            type: Number,
            default: 250
        },
        globalSearchHandler: {
            default: null
        }
    },
    data () {
        return {
            neighborhood_ids: [],
            mls_id: "",
            onboarding: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.page-waiting {
    position: relative;
    width: 100%;
    height: 100vh;

    & > .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.page {
    margin-left: auto;
    margin-right: auto;
    padding-left: $layout-spacing;
    padding-right: $layout-spacing;
    width: 100%;

    .page-content-header {
        h3 {
            font-weight: 500;
            letter-spacing: 1px;
            padding: 0.1rem 0;

            & + .page-back {
                border-left: $border-width solid $border-color;
                padding: 0.3rem 1.2rem 0.4rem 1.2rem;
                font-size: clamp(0.5rem, 0.4143rem + 4762vw, 0.7rem);
                line-height: 1.2rem;
                margin: 0 0 1em 0;
            }
        }
    }
    .page-content-footer {
        position: sticky;
        bottom: 0;
        margin-left: 0 - $layout-spacing - $unit-6;
        margin-right: 0 - $layout-spacing - $unit-6;
        padding: $control-padding-x-lg ($layout-spacing + $unit-6);
        background-color: $light-color;
        box-shadow: 0 -3px 10px 0px rgb(0 0 0 / 10%);
        z-index: $zindex-3;
    }

    #onboarding {
        .empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            .empty-action {
                text-align: center;
                width: 90%;
                max-width: 300px;
            }
        }
    }

    &.narrow {
        padding-left: 15vw;
        padding-right: 15vw;
    }
    @media (max-width: $size-sm) {
        &.narrow {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}
</style>
