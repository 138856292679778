import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

const addl_profile_utilities = {
    mixins: [profile_utilities],
    methods: {
        role_is_realtor (role) {
            return (role === this.constants("roles", "ROLE_REALTOR").id)
        },
        role_is_at_least_realtor (role) {
            return (role >= this.constants("roles", "ROLE_REALTOR").id)
        },
        role_is_at_least_seller_for_listing (user, listing) {
            if(this.role_is_at_least_realtor(user.role)) return true
            return !!listing.seller_ids && (listing.seller_ids.indexOf(user.id) > 0)
        },
        is_at_least_seller_for_listing (listing) {
            return this.role_is_at_least_seller_for_listing(this.profile, listing)
        },
        is_seller_for_listing (listing) {
            return !!listing.seller_ids && (listing.seller_ids.indexOf(this.profile.id) > 0)
        },
        role_is_at_least_realtor_for_listing (user, listing) {
            if(this.role_is_at_least_administrator(user.role)) return !!listing && !!listing.seller_realtor_ids && !!listing.seller_realtor_ids.length
            return this.role_is_realtor(user.role) && listing && !!listing.seller_realtor_ids && (listing.seller_realtor_ids.indexOf(user.id) >= 0)
        },
        is_at_least_realtor_for_listing (listing) {
            return this.role_is_at_least_realtor_for_listing(this.profile, listing)
        }
    },
    computed: {
        is_at_least_realtor () {
            return this.is_realtor || this.is_administrator || this.is_developer
        },
        is_realtor () {
            if(!this.profile_id) return false
            return this.role_is_realtor(this.profile.role)
        }
    }
}

export default addl_profile_utilities
