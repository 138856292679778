import ListModePicker from "@/custom/components/ListModePicker"

const DISPLAY_MODE_LIST = "list"
const DISPLAY_MODE_CARDS = "cards"
const DISPLAY_MODE_MAP = "map"

const DISPLAY_MODES = {
    DISPLAY_MODE_LIST,
    DISPLAY_MODE_CARDS,
    DISPLAY_MODE_MAP
}

const lists_listings = {
    components: {
        ListModePicker
    },
    created () {
        this.DISPLAY_MODE_LIST = DISPLAY_MODE_LIST
        this.DISPLAY_MODE_CARDS = DISPLAY_MODE_CARDS
        this.DISPLAY_MODE_MAP = DISPLAY_MODE_MAP
        const list_mode_cookie_name = this.list_mode_cookie_name
        const list_mode = list_mode_cookie_name ? this.$cookie.get(list_mode_cookie_name) : DISPLAY_MODE_LIST
        this.set_list_mode(list_mode || DISPLAY_MODE_LIST)
    },
    methods: {
        set_list_mode (list_mode) {
            this.list_mode = list_mode
            const list_mode_cookie_name = this.list_mode_cookie_name
            if(list_mode_cookie_name) this.$cookie.set(list_mode_cookie_name, list_mode, { expires: "1Y" })
        }
    },
    computed: {
        list_mode_cookie_name () {
            return "derekgillette-list-mode"
        }
    },
    data () {
        return {
            list_mode: null
        }
    }
}

export { lists_listings, DISPLAY_MODE_LIST, DISPLAY_MODE_CARDS, DISPLAY_MODE_MAP, DISPLAY_MODES }
