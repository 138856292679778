import addl_profile_utilities from "./AddlProfileUtilities"
import uses_destination_modal from "./UsesDestinationModal"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import VueApexCharts from "vue-apexcharts"

const displays_historical_trends = {
    mixins: [addl_profile_utilities, uses_destination_modal],
    components: {
        FormDropdown,
        Apexchart: VueApexCharts
    },
    mounted () {
        this.range = this.range_for_years()
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        $route: "refresh",
        showingsDataVersion: "refresh"
    },
    methods: {
        range_for_years (year_count = 1) {
            return { from: this.$dayjs().subtract(year_count, "year").format("YYYY-MM-DD"), to: this.$dayjs().format("YYYY-MM-DD") }
        },
        refresh () {
            if(!this.profile_id || !this.is_at_least_realtor) return
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "user",
                    action: "trends",
                    method: "get",
                    data: {
                        range: this.range_for_years(this.year_count),
                        listing_id: this.listingId
                    },
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        },
        chart_options (id) {
            return {
                chart: {
                    id,
                    height: (this.zoomed === id) ? 400 : this.default_height,
                    type: "line",
                    fontFamily: "Quicksand, sans-serif",
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                legend: {
                    show: false
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        right: 30
                    }
                },
                dataLabels: {
                    enabled: (this.zoomed === id)
                },
                xaxis: {
                    show: (this.zoomed === id),
                    type: "datetime",
                    tickAmount: 12,
                    categories: (!this.statistics || !this.statistics[id]) ? [] : Object.keys(this.statistics[id].data),
                    labels: {
                        datetimeFormatter: {
                            year: "yyyy",
                            month: "MMM",
                            day: "dd MMM",
                            hour: "HH:mm"
                        }
                        /*
                        formatter: (value, timestamp, options) => {
                            if(!options.w.config.series || !options.w.config.series[0]) return value
                            if(!!options.i && (options.i < (options.w.config.series[0].data.length - 1))) return this.$dayjs(new Date(timestamp)).format("MMM")
                            return this.$dayjs(new Date(timestamp)).format("YYYY")
                        }
                        */
                    }
                },
                yaxis: {
                    labels: {
                        formatter: (value) => {
                            return this.nibnut_filter("nibnut.number", [value, "0,0"])
                        }
                    }
                }
            }
        },
        chart_data (id) {
            if(!this.statistics || !this.statistics[id]) return []
            return [
                {
                    name: this.statistics[id].name,
                    data: Object.values(this.statistics[id].data)
                }
            ]
        }
    },
    computed: {
        years () {
            return [...Array(5)].map((noop, index) => {
                const id = index + 1
                return { id, name: this.translate("One year:::{id} years", { id }, id) }
            })
        }
    },
    props: {
        showingsDataVersion: {
            type: Number,
            default: 0
        },
        listingId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            year_count: 1,
            statistics: null,
            default_height: 200
        }
    }
}

export default displays_historical_trends
